<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Form Submissions
        </h1>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <div v-if="items.length || search != ''"  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/form-submissions/" :columns="columns" :items="items"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
    </div>
    <div  v-else  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/form-submissions/" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
    </div>
</div>
</template>

<script>
import List from '../../../mixins/List'
export default {
    name: 'FormSubmissionsList',
    mixins: [List],
    data: () => ({
        limit: 10,
        page: 0,
        action: '/form-submissions',
        searchParams: {
            'model': 'FormSubmission',
            'fields': ['id','fields','form_id','order_id','user_id'],
        },
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'form_id',
                label:'Form ID',
            },
            {
                id:'order_id',
                label:'Order ID',
            },
            {
                id:'customer',
                label:'Customer',
                custom: 'DisplayCustomer',
            },
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        DisplayCustomer(item) {
            if(item.customer) return item.customer.email
            return 'N/A'
        },
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
