<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Form Submission
        </h1>
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/form-submission" :formdata="formData">
                <TextInput type="text" label="Form ID" v-model="item.form_id" :required="false" ></TextInput>
                <TextInput type="text" label="Order ID" v-model="item.order_id" :required="false" ></TextInput>
                <TextInput type="text" label="User ID" v-model="item.user_id" :required="false" ></TextInput>
                <TextInput type="text" label="IP Address" v-model="item.from_ip" :required="false" ></TextInput>
                <TextInput type="text" label="From Site" v-model="item.from_site" :required="false" ></TextInput>
                <div class="h-full w-full overflow-auto">
                    <JsonEditor
                        :options="{
                            confirmText: 'confirm',
                            cancelText: 'cancel',
                        }"
                        :objData="item.fields" 
                        v-model="item.fields" >
                    </JsonEditor>
                </div>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'

export default {
    name: 'Note',
    mixins: [View],
    data: () => ({
        parameter: 'form_submission_id',
        action: '/form-submission/',
        tab_content: {
        }
    }),
    computed: {
        formData() {
            return {
                'formSubmission':this.item,
            }
        },
    },
    props: [],
    methods: {
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
        // itemFetched() {
        //     this.item.fields = JSON.parse(this.item.fields)
        // },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
